import * as React from "react";
import "./development-process.scss";
import Section from "../Section/section";
import {Linolade} from "../Linolade/linolade";
import {SectionHeader} from "../Section/SectionHeader/section-header";
import {useTranslation} from "react-i18next";
import Space from "../Space/space";
import Container from "../Container/container";
import DevelopmentProcessStep from "./DevelopmentProcessStep/development-process-step";
import DevelopmentStepsAsset from "../../../assets/development-steps.svg";

const DevelopmentProcess = () => {
    const {t} = useTranslation();
    const developmentStepsTranslations = t('howWeWork.developmentProcess.steps', {returnObjects: true}) as object[];
    return (
        <Section sectionId={"developmentProcess"}>
            <Linolade/>
            <SectionHeader text={t('howWeWork.developmentProcess.text')} highlightedText={t('howWeWork.developmentProcess.highlightedText')}/>
            <Container><p>{t('howWeWork.developmentProcess.description')}</p></Container>
            <Space/>
            <div className="development-process_steps">
                <DevelopmentStepsAsset className="development-process_image"/>
                <div className="development-process_steps-description">
                    {developmentStepsTranslations.map((step: any, i: number) =>
                        <DevelopmentProcessStep title={step.title} description={step.description}/>
                    )}
                </div>
            </div>
        </Section>
    );
};

export default DevelopmentProcess;