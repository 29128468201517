import * as React from "react";
import "./technologies-section.scss";
import Section from "../Section/section";
import {Linolade} from "../Linolade/linolade";
import {SectionHeader} from "../Section/SectionHeader/section-header";
import {useTranslation} from "react-i18next";
import Container from "../Container/container";
import TechnologiesAsset from "../../../assets/technologies.svg";
import Space from "../Space/space";

const TechnologiesSection = () => {
    const {t} = useTranslation();
    return (
        <Section sectionId={"technologies"}>
            <Linolade/>
            <SectionHeader text={t('howWeWork.technologies.text')}
                           highlightedText={t('howWeWork.technologies.highlightedText')}/>
            <Container>
                <p>{t('howWeWork.technologies.description')}</p>
            </Container>
            <Space/>
            <TechnologiesAsset className="technologies_image"/>
        </Section>
    )
}

export default TechnologiesSection;