import * as React from "react"
import type { HeadFC } from "gatsby"
import Navbar from "../components/Navbar/navbar";
import SubPageTitle from "../components/SubPageTitle/sub-page-title";
import {useTranslation} from "react-i18next";
import {graphql} from "gatsby";
import TechnologiesSection from "../components/TechnologiesSection/technologies-section";
import {NegativeSpace} from "../components/NegativeSpace/negative-space";
import {SEO} from "../components/Seo/seo";
import DevelopmentProcess from "../components/DevelopmentProcess/development-process";
import StillHaveQuestions from "../components/StillHaveQuestions/still-have-questions";

const HowWeWorkPage = ()=>{
    const {t} = useTranslation()
    return(
        <main>
            <SubPageTitle title={t('howWeWork.title')} subtitle={t('howWeWork.subtitle')}/>
            <NegativeSpace/>
            <TechnologiesSection/>
            <NegativeSpace/>
            <DevelopmentProcess/>
            <NegativeSpace/>
            <StillHaveQuestions/>
        </main>
    )
}

export default HowWeWorkPage;

export const Head: HeadFC = () => <SEO />

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;