import * as React from "react";
import "./development-process-step.scss";

const DevelopmentProcessStep = (props: { title: string, description: string }) => {
    
    return (
        <div className="development-process-step">
            <h3>{props.title}</h3>
            <div className="development-process-step_line"></div>
            <p>{props.description}</p>
        </div>
    )
}

export default DevelopmentProcessStep;