import * as React from "react";
import "./still-have-questions.scss";
import Section from "../Section/section";
import {Linolade} from "../Linolade/linolade";
import {SectionHeader} from "../Section/SectionHeader/section-header";
import {useTranslation} from "react-i18next";
import Space from "../Space/space";
import {Link} from "gatsby-plugin-react-i18next";

const StillHaveQuestions = () => {
    const {t} = useTranslation();
    return (
        <Section sectionId={"still-have-questions"}>
            <Linolade/>
            <SectionHeader text={t('howWeWork.stillHaveQuestions.text')} highlightedText={t('howWeWork.stillHaveQuestions.highlightedText')}/>
            <Space/>
            <Link to={"/#contact"} className="still-have-questions_button" aria-label="contact-us-now">
                {t('howWeWork.stillHaveQuestions.buttonText')}
            </Link>
        </Section>
    );
}

export default StillHaveQuestions;